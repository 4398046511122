<template>
  <div class="tablecontain">
    <div class="infocontain">
      <div class="tip" v-show="ruleInfo.tips" v-if="isTip">
        <span>{{ ruleInfo.tips }}</span>
        <div class="tip-btn">
          <i class="el-icon-close" @click="isTip = false"></i>
        </div>
      </div>
      <div class="info">
        <div class="title">{{ info.lab_name }}</div>
        <div class="content">
          <span>课程总学分： {{ info.course_lib_credit }}</span>
          <span>总学时： {{ info.course_lib_class_hours }}</span>
        </div>
        <div class="content_1">
          <span>预约规则：</span>
          <span
            v-if="
              ruleInfo.min_class_hours !== 0 || ruleInfo.max_class_hours !== 0
            "
          >
            至少预约{{ ruleInfo.min_class_hours }} 学时；最多预约
            {{ ruleInfo.max_class_hours }} 学时；
          </span>
          <span v-if="ruleInfo.is_must_select === 1">必须选择一个必修实验项目；</span>
          <span v-if="ruleInfo.multi_experiment === 1">可预约多个项目；</span>
          <span v-if="ruleInfo.multi_section === 1">可预约多个节次；</span>
          <span
            v-if="
              ruleInfo.cancel_book_time === '' ||
              ruleInfo.cancel_book_time === null
            "
          >预约后开课当天不能取消预约；</span>
          <span v-if="ruleInfo.cancel_book_time === 0">预约后随时可以取消预约；</span>
          <span v-if="ruleInfo.cancel_book_time">
            预约后距离开课时间前
            {{ ruleInfo.cancel_book_time }}
            小时可以取消预约；
          </span>
          <!-- cancel_book_time -->
        </div>
      </div>
    </div>
    <div class="searchcontain">
      <div class="left">
        <!-- <div class="searchitem">
          全部、已预约 已约满  
          <div class="maintext">预约状态:</div>
          <el-select class="elinput" v-model="queryParams.status" placeholder="请选择" size="medium ">
            <el-option v-for="item in statusData" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>-->
        <div class="searchitem">
          <div class="maintext">起始周:</div>
          <el-select
            class="elinput"
            v-model="queryParams.start_week"
            placeholder="请选择"
            size="medium"
          >
            <el-option v-for="item in weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="searchitem">
          <div class="maintext">截止周:</div>
          <el-select class="elinput" v-model="queryParams.end_week" placeholder="请选择" size="medium">
            <el-option v-for="item in weeknum" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="searchitem">
          <div class="maintext">星期:</div>
          <el-select
            class="elinput"
            v-model="queryParams.day"
            clearable
            placeholder="请选择"
            size="medium"
          >
            <el-option
              v-for="item in weekDays"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="searchitem">
          <div class="maintext">节次:</div>
          <el-select
            class="elinput"
            v-model="queryParams.lesson_sn"
            clearable
            placeholder="请选择"
            size="medium"
          >
            <el-option
              v-for="item in lessons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="right">
        <div class="bt searchbt" @click="handelSearch">查询</div>
        <div class="bt restbt" @click="handelRreset">重置</div>
      </div>
    </div>
    <div class="tablebox">
      <el-checkbox v-model="checked" @change="is_valid">可预约</el-checkbox>
    </div>
    <el-table
      ref="multipleTable"
      class="table"
      v-loading="loading"
      :data="tableData"
      border
      highlight-current-row
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }"
      :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }"
      @current-change="handleCurrentChange"
      @selection-change="handleSelectionChange"
      :row-key="getRowKeys"
    >
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="50"
        :reserve-selection="true"
      ></el-table-column>
      <el-table-column ref="columnRef" width="1">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.is_can_book.msg" :disabled="!row.is_disabled" placement="top">
            <div class="tooltipbtn"></div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="experiment_name" label="实验项目" min-width="150" align="center"></el-table-column>
      <el-table-column prop="is_must_select" label="实验要求" min-width="120" align="center"></el-table-column>
      <el-table-column prop="class_hours" label="学时" min-width="60" align="center"></el-table-column>
      <el-table-column prop="week_number" label="周次" min-width="60" align="center"></el-table-column>
      <el-table-column prop="day" label="星期" min-width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.day | dayFilter }}{{ scope.row.date | dataStrformat }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lesson_period" label="节次" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.lesson_period.toString() }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="book_count" label="已预约人数" min-width="120" align="center">
        <template slot-scope="scope">
          <span v-if="Number(scope.row.remain_count) > 0">{{ scope.row.book_count }}</span>
          <span v-if="Number(scope.row.remain_count) <= 0">已满</span>
          <!-- <span v-else-if="Number(scope.row.is_can_book.status) === 0">{{
           scope.row.
          }}</span>-->
        </template>
      </el-table-column>
      <!-- <el-table-column prop="remain_count" label="可预约人数" min-width="120" align="center"></el-table-column> -->
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="queryParams.per_page"
        :current-page="queryParams.page"
        background
        :page-sizes="[10, 20, 30, 50]"
        @size-change="changeSize"
        @current-change="changePage"
        :pager-count="5"
        v-if="isMobile == 0"
      ></el-pagination>
      <el-pagination
        layout="sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="queryParams.per_page"
        :current-page="queryParams.page"
        background
        :page-sizes="[10, 20, 30, 50]"
        @size-change="changeSize"
        @current-change="changePage"
        v-if="isMobile == 1"
      ></el-pagination>
    </div>
    <div class="handelReserve">
      <el-button type="info" @click="close">取消</el-button>
      <el-button type="primary" :loading="isReserveload" @click="handelReserve">预约</el-button>
    </div>

    <!-- <div class="bt searchbt" @click="handelReserve">取消</div>
    <div class="bt searchbt" @click="handelReserve">预约</div>-->
  </div>
</template>

<script>
import dayjs from "dayjs"
import {
  getExperimentRuleInfo,
  getExperimentLabRoomWeekList,
} from "@/api/openexperiment" // 获取开放实验实验用房周次列表
import { openexperimentbook } from "@/api/openexperimentbook" // //开放实验预约
const dayMap = {
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
  7: "星期日",
}
let number_mask = 0
let num = 0
export default {
  name: "Home",
  components: {},
  props: {
    closebt: {
      type: Function,
    },
  },
  data () {
    return {
      info: {}, // 当前实验信息
      type: "", // 项目类型
      weeknum: "", // 周次数
      isTip: true, // 是否显示提示
      ruleInfo: {},
      queryParams: {
        open_experiment_id: "",
        start_week: "",
        end_week: "",
        per_page: 10,
        page: 1,
      },
      tableData: [],
      multipleSelection: "",
      total: 0,
      weekDays: [
        { id: 1, label: "星期一", value: 1 },
        { id: 2, label: "星期二", value: 2 },
        { id: 3, label: "星期三", value: 3 },
        { id: 4, label: "星期四", value: 4 },
        { id: 5, label: "星期五", value: 5 },
        { id: 6, label: "星期六", value: 6 },
        { id: 7, label: "星期天", value: 7 },
      ], // 星期列表
      lessons: [
        { id: 1, label: "第一节课", value: 1 },
        { id: 2, label: "第二节课", value: 2 },
        { id: 3, label: "第三节课", value: 3 },
        { id: 4, label: "第四节课", value: 4 },
        { id: 5, label: "第五节课", value: 5 },
        { id: 6, label: "第六节课", value: 6 },
        { id: 7, label: "第七节课", value: 7 },
        { id: 8, label: "第八节课", value: 8 },
        { id: 9, label: "第九节课", value: 9 },
      ], // 课节列表
      experiment_name: "",
      experiment_type: null,
      isReserveload: false,
      checked: false,
      loading: true,
      isMobile: "",
      currentWeek: 0,
    }
  },
  filters: {
    dataformat (value) {
      if (!value) return ""
      let time = dayjs.unix(value).format("YYYY.MM.DD")
      return "(" + time + ")"
    },
    dataStrformat (value) {
      let str = value.toString()
      let y = str.substr(0, 4)
      let m = str.substr(4, 2)
      let d = str.substr(6, 2)
      return "(" + m + "." + d + ")"
    },
    dayFilter (value) {
      return dayMap[value]
    },
  },
  created () {
    if (this._isMobile()) {
      this.isMobile = 0
    } else {
      this.isMobile = 1
    }
  },
  methods: {
    // 判断手机端
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    inidata (item, weeknum, currentWeek, type) {
      // console.log(item, weeknum, type);
      this.isTip = true
      this.info = item
      this.type = type // 获取项目类型
      this.weeknum = parseInt(weeknum) // 获取周次数
      this.currentWeek = currentWeek // 当前周次
      this.queryParams.start_week = currentWeek
      /* 整理参数 */
      this.queryParams.open_experiment_id = item.id // 开放实验ID
      this.queryParams.lab_room_id = item.lab_room_id // 实验用房ID
      this.experiment_type = item.experiment_type // 项目类型
      this.queryParams.page = 1
      this.getRuleInfo()
      this.getlessonlist()
    },
    getRuleInfo () {
      let params = { id: this.info.id }
      getExperimentRuleInfo(params).then((res) => {
        if (res.code === 0 && res.data) {
          this.ruleInfo = res.data
          this.$refs.multipleTable.clearSelection()
        }
      })
    },

    // 获取开放实验实验用房周次列表
    getlessonlist () {
      // console.log('2024',2024);
      let params = this.queryParams
      params.week_no = 1
      this.loading = true
      getExperimentLabRoomWeekList(params)
        .then((res) => {
          if (res.code === 0) {
            // console.log("实验用房周次列表", res.data);
            this.total = res.data.total
            this.tableData = res.data.data?.map((item) => {
              let isBook = true
              if (item.is_can_book) {
                isBook = item.is_can_book.status == 1 ? true : false // 是否可以预约
              }
              // let isFull = item.is_can_book.msg; // 是否满员
              // item.is_can_book.msg = isFull ? "已满员" : item.is_can_book.msg;
              item.is_disabled = !isBook
              return item
            })
            this.loading = false
            this.tableData.forEach((item) => {
              if (item.booked == 1) {
                this.$refs.singleTable.toggleRowSelection(item, true)
              }
            })
          }
        })
        .catch((error) => {
          this.loading = false
          //console.log(error);
        })
    },
    is_valid () {
      console.log(this.checked)
      if (this.checked === true) {
        this.queryParams.is_valid = 1
        this.getlessonlist()
      } else {
        this.queryParams.is_valid = 0
        this.getlessonlist()
      }
    },
    // 分页切换 - 单页条数
    changeSize (val) {
      this.queryParams.per_page = val
      this.getlessonlist()
    },
    // 分页切换 - 页面
    changePage (val) {
      this.queryParams.page = val
      this.getlessonlist()
    },
    // 表格条数调色
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex > 0) {
        let f1 = row.week_number == this.tableData[rowIndex - 1].week_number
        let f2 = row.day == this.tableData[rowIndex - 1].day
        let f3 =
          row.lesson_period.toString() ==
          this.tableData[rowIndex - 1].lesson_period.toString()
        if (f1 && f2 && f3) {
          number_mask = number_mask + 2
        } else {
          number_mask = number_mask + 1
        }
      }
      if (number_mask % 2 !== 0) {
        return "warning-row"
      } else {
        return ""
      }
    },
    // 表格选择操作
    selectable (row, index) {
      num = 0
      let isBook = true
      if (row.is_can_book) {
        isBook = row.is_can_book.status == 1 ? true : false // 是否可以预约
      }

      // let isFull = Number(row.remain_count) === 0 ? true : false; // 是否满员
      let flag = isBook
      return flag
    },
    // 表格当前选中行当前
    handleCurrentChange (newRow, oldRow) {
      // console.log(newRow, oldRow);
      num = 0
    },
    // 获取多选结果
    handleSelectionChange (val) {
      // console.log("勾选结果",val)
      this.multipleSelection = val
      //console.log("是否可预约多项目", this.ruleInfo.multi_experiment);
      //console.log("是否可预约多节次", this.ruleInfo.multi_section);
    },
    getRowKeys (row) {
      return row.course_lib_weekly_lesson_id
    },
    // 查询搜索
    handelSearch () {
      this.queryParams.page = 1
      this.getlessonlist()
    },
    // 重置查询搜索
    handelRreset () {
      this.queryParams.start_week = ""
      this.queryParams.end_week = ""
      this.queryParams.day = ""
      this.queryParams.lesson_sn = ""
      this.queryParams.page = 1
      this.queryParams.per_page = 10
      this.getlessonlist()
    },
    // 提交预约
    handelReserve () {
      // 判断勾选是否有值
      if (this.multipleSelection.length > 0) {
        // 判断项目是否全部命名
        let flagEn = false
        let lesson_arr = this.multipleSelection.map((el) => {
          if (el.experiment_name == "" || el.experiment_name == null) {
            flagEn = true
          }
          let objct = {
            experiment_id: el.experiment_id, // 实验项目ID
            experiment_name: el.experiment_name, // 实验项目课节ID，多个用逗号分隔
            course_lib_weekly_lesson_id: el.course_lib_weekly_lesson_id, // 自拟实验标题,自拟实验必传（暂时废弃）
          }
          return objct
        })
        if (flagEn) {
          this.$message.warning("请输入项目名称")
          return
        }

        // 防抖
        if (this.isReserveload) {
          return false
        }
        this.isReserveload = true
        // 整合参数
        let params = {
          open_experiment_id: this.queryParams.open_experiment_id, // 开放实验ID
          lesson_arr: JSON.stringify(lesson_arr),
        }
        // 预约
        //console.log("预约参数", params);
        openexperimentbook(params)
          .then((res) => {
            this.isReserveload = false
            //console.log("预约", res);
            if (res.code === 0) {
              this.$message.success("预约成功！")
              this.closebt()
              this.multipleSelection = []
              this.getlessonlist()
              this.$emit("opreated")
            }
          })
          .catch((err) => {
            this.$message.error(err)
            this.getlessonlist()
            this.isReserveload = false
          })
      } else {
        this.$message.warning("至少选择一项！")
      }
    },
    // 取消预约
    close () {
      //  this.$emit('check',false)
      this.closebt()
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .tablecontain {
    // 信息栏
    .infocontain {
      .tip {
        width: 100%;
        min-height: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 40px;
        background-color: #e1efff;
        box-sizing: border-box;
        position: relative;

        .tip-btn {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .info {
        padding: 10px;

        .title {
          font-size: 20px;
          line-height: 2;
          margin-bottom: 10px;
        }

        .content {
          width: 100%;
          height: 40px;
          line-height: 40px;

          span {
            margin-right: 10px;
          }
        }

        .content_1 {
          width: 100%;
          // height: 40px;
          line-height: 40px;
          color: red;
        }
      }
    }

    // 搜索栏
    .searchcontain {
      background: #fcfcfc;
      border: 1px solid #ececec;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      padding: 15px 10px;
      width: calc(100% - 23px);

      .left {
        // display: flex;
        // flex-wrap: wrap;

        .searchitem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 10px 10px;

          .maintext {
            margin-right: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #888888;
            width: 20%;
          }

          .elinput {
            width: 70%;
            margin-right: 5%;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }

        .restbt {
          color: #3d84ff;
          margin-right: 10px;
        }

        .searchbt {
          margin-right: 10px;
          border: 1px solid #3d84ff;

          background: #3d84ff;
          color: white;
        }
      }
    }

    .handelReserve {
      // float: right;
      width: 97%;
      padding-top: 30px;
      text-align: right;
    }

    // 表单
    .table {
      width: 100%;
      margin-top: 10px;
      padding: 10px;

      .elinput {
        width: 120px;
      }

      .opreatbt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dedede;
      padding-bottom: 40px;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    ::v-deep .el-button--primary {
      background: #3d84ff;
    }

    .tablebox {
      height: 40px;
      line-height: 50px;
      text-align: right;
      padding-right: 20px;
    }
  }

  ::v-deep .el-table .warning-row {
    background-color: #ecf8ff !important;
  }

  .tooltipbtn {
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50px;
  }
}
@media screen and (min-width: 1200px) {
  .tablecontain {
    // 信息栏
    .infocontain {
      .tip {
        width: 100%;
        min-height: 30px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 40px;
        background-color: #e1efff;
        box-sizing: border-box;
        position: relative;

        .tip-btn {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .info {
        padding: 10px;

        .title {
          font-size: 20px;
          line-height: 2;
          margin-bottom: 10px;
        }

        .content {
          width: 100%;
          height: 40px;
          line-height: 40px;

          span {
            margin-right: 10px;
          }
        }

        .content_1 {
          width: 100%;
          // height: 40px;
          line-height: 40px;
          color: red;
        }
      }
    }

    // 搜索栏
    .searchcontain {
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 10px;

      .left {
        display: flex;
        flex-wrap: wrap;

        .searchitem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 10px 10px;

          .maintext {
            margin-right: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #888888;
          }

          .elinput {
            width: 200px;

            ::v-deep .el-input__suffix {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }

        .restbt {
          color: #3d84ff;
          margin-right: 10px;
        }

        .searchbt {
          margin-right: 10px;
          border: 1px solid #3d84ff;

          background: #3d84ff;
          color: white;
        }
      }
    }

    .handelReserve {
      // float: right;
      width: 97%;
      padding-top: 30px;
      text-align: right;
    }

    // 表单
    .table {
      width: 100%;
      margin-top: 10px;
      padding: 10px;

      .elinput {
        width: 120px;
      }

      .opreatbt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dedede;
      padding-bottom: 40px;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    .elpagination {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }

    ::v-deep .el-button--primary {
      background: #3d84ff;
    }

    .tablebox {
      height: 40px;
      line-height: 50px;
      text-align: right;
      padding-right: 20px;
    }
  }

  ::v-deep .el-table .warning-row {
    background-color: #ecf8ff !important;
  }

  .tooltipbtn {
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50px;
  }
}
</style>
